export const BUSINESS_TYPE = {
  SERVICE_PROVIDER: 'SERVICE_PROVIDER',
  RESTAURANT: 'RESTAURANT',
  SUPPLIER: 'SUPPLIER',
  BOOK_KEEPING: 'BOOK_KEEPING',
};

export const ROLE_TYPE = {
  ADMIN: 'ADMIN',
  ORDERER: 'ORDERER',
  PURCHASE_MANAGER: 'PURCHASE_MANAGER',
  MANAGER: 'MANAGER',
  PAYMENTS_MANAGER: 'PAYMENTS_MANAGER',
  OWNER: 'OWNER',
  EMPLOYEE: 'EMPLOYEE',
};
