import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';

export const useUserCreate = () => {
  const { mutate, onError, onDone } = useMutation(USER_CREATE_MUTATION);

  onError((err) => {
    console.error('users - useUserCreate', err);
  });

  return {
    createUser: mutate,
    onError,
    onDone,
  };
};

export const USER_CREATE_MUTATION = gql`
  mutation userCreate($createParams: UserCreateInput) {
    userCreate(createParams: $createParams)
  }
`;
