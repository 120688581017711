<template>
  <el-dialog
    top="30vh"
    visible
    :show-close="false"
    custom-class="rounded edit-user-modal"
    width="800px"
    @close="$emit('close')"
  >
    <template #title>
      <div class="d-flex justify-content-between align-items-center p-4 border-bottom">
        <h2>{{ $t('userManagement.userEditModal.title') }}</h2>
        <Button type="text" class="p-0 text-typography-primary action-btn" @click="$emit('close')">
          <CloseIcon />
        </Button>
      </div>
    </template>
    <div v-loading="createMembershipLoading || deleteMembershipLoading" class="py-4 px-4">
      <div class="row pb-5">
        <div class="col-2 d-flex flex-column">
          <p class="fw-bold">{{ $t('userManagement.userEditModal.fullName') }}</p>
          <p class="fw-bold">{{ $t('userManagement.userEditModal.email') }}</p>
        </div>
        <div class="col d-flex flex-column">
          <p>{{ fullName }}</p>
          <p>{{ user.email }}</p>
        </div>
      </div>
      <div class="d-flex justify-content-between py-1">
        <p class="fw-bold">{{ $t('userManagement.userEditModal.associatedOrganizations') }}</p>
        <Button type="text" class="fw-normal" @click="addMembershipModalOpen = true">{{
          $t('userManagement.userEditModal.attachToOrganization')
        }}</Button>
      </div>
      <ul class="list-group">
        <li v-for="(businessWithRole, rowIndex) in userMemberships" :key="rowIndex" class="list-group-item px-4 py-2">
          <div class="row">
            <div class="col">
              <p>{{ businessWithRole.business }}</p>
            </div>
            <div class="col">{{ businessWithRole.role }}</div>
            <el-dropdown
              class="col-1 d-flex justify-content-end"
              trigger="click"
              placement="bottom"
              @command="handleAction($event, businessWithRole)"
              @visible-change="(isVisible) => actionsVisibleChange(rowIndex, isVisible)"
            >
              <Button
                :id="`actions-row-${rowIndex}`"
                type="text"
                class="p-0 actions-btn text-typography-primary"
                :class="{ active: activeActions === rowIndex }"
                @click.stop
              >
                <KebabIcon />
              </Button>
              <el-dropdown-menu>
                <el-dropdown-item :command="ACTIONS.DELETE_MEMBERSHIP">
                  <div class="d-flex align-items-center gap-1 text-typography-primary">
                    <TrashCanIcon />
                    <p>{{ $t('userManagement.userEditModal.actions.removeFromOrganization') }}</p>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </li>
      </ul>
    </div>
    <template #footer>
      <div class="pb-4 px-4">
        <Button type="secondary" @click="$emit('close')">
          {{ $t('commons.close') }}
        </Button>
      </div>
    </template>
    <AddMembershipModal
      v-if="addMembershipModalOpen"
      :tenants="tenantsToAdd"
      @close="addMembershipModalOpen = false"
      @create="addMembership"
    />
  </el-dialog>
</template>

<script>
import { ref, computed, getCurrentInstance } from 'vue';

import { Button } from '@/modules/core';
import { CloseIcon, TrashCanIcon, KebabIcon } from '@/assets/icons';

import { useMembershipCreate, useMembershipDelete } from '../compositions';
import AddMembershipModal from './AddMembershipModal';
import { ROLE_TYPE, BUSINESS_TYPE } from '../constants';

const ACTIONS = {
  DELETE_MEMBERSHIP: 'deleteUserMembership',
};

export default {
  components: { Button, CloseIcon, TrashCanIcon, KebabIcon, AddMembershipModal },
  props: {
    user: { type: Object, required: true, default: () => ({}) },
    tenants: { type: Array, default: () => [] },
  },
  emits: ['send'],
  setup(props, { emit }) {
    const root = getCurrentInstance().proxy;
    const userMemberships = ref(props.user.businessWithRoles);
    const isOtherEmailSelected = ref(false);
    const formModel = ref({
      otherEmail: null,
    });
    const activeActions = ref(-1);
    const addMembershipModalOpen = ref(false);

    const fullName = computed(() => `${props.user?.fullName?.firstName} ${props.user?.fullName?.lastName}`);
    const tenantsToAdd = computed(() => {
      const businessNames = (props.user?.businessWithRoles || []).map(({ business }) => business);
      return props.tenants.filter(({ name }) => !businessNames.includes(name));
    });

    const {
      createMembership,
      onDone: createMembershipOnDone,
      onError: createMembershipOnError,
      loading: createMembershipLoading,
    } = useMembershipCreate();

    const {
      deleteMembership,
      onDone: deleteMembershipOnDone,
      onError: deleteMembershipOnError,
      loading: deleteMembershipLoading,
    } = useMembershipDelete();

    createMembershipOnDone((response) => {
      const { id, businessId, role } = response.data.membershipCreate;
      const targetTenant = props.tenants.find((tenant) => tenant.id === businessId);
      let roleToDisplay = root.$t(`userManagement.roles.${role.toLowerCase()}`);
      if (targetTenant.type === BUSINESS_TYPE.BOOK_KEEPING && role === ROLE_TYPE.MANAGER) {
        roleToDisplay = root.$t('userManagement.roles.bookkeepingEmployee');
      }
      if (targetTenant.type === BUSINESS_TYPE.SUPPLIER && role === ROLE_TYPE.EMPLOYEE) {
        roleToDisplay = root.$t('userManagement.roles.supplierEmployee');
      }
      const createdMembership = {
        business: targetTenant.name,
        category: targetTenant.type,
        membershipId: id,
        role: roleToDisplay,
      };
      userMemberships.value = [...userMemberships.value, createdMembership];

      root.$message.success(root.$t('userManagement.userEditModal.messages.createMembershipSuccess'));
    });
    createMembershipOnError(() =>
      root.$message.error(root.$t('userManagement.userEditModal.messages.createMembershipFailed'))
    );

    deleteMembershipOnDone(() => {
      root.$message.success(root.$t('userManagement.userEditModal.messages.removeMembershipSuccess'));
    });
    deleteMembershipOnError(() =>
      root.$message.error(root.$t('userManagement.userEditModal.messages.removeMembershipFailed'))
    );

    const handleSaveButtonClick = () => {
      if (isOtherEmailSelected.value) {
        root.$refs.form.validate(async (valid) => {
          if (valid) {
            emit('send', formModel.value.otherEmail);
          }
        });
      } else {
        emit('send', null);
      }
    };

    const actionsVisibleChange = (index, isVisible) => {
      activeActions.value = isVisible ? index : -1;
    };
    const handleAction = (command, businessWithRole) => {
      switch (command) {
        case ACTIONS.DELETE_MEMBERSHIP:
          deleteUserMembership(businessWithRole);
          break;
        default:
          break;
      }
    };
    const addMembership = ({ businessId, role }) => {
      createMembership({
        createParams: {
          userId: props.user.id,
          businessId,
          role,
        },
      });
    };

    const deleteUserMembership = (businessWithRole) => {
      root
        .$confirm(
          root.$t('userManagement.userEditModal.removeConfirmationMessage', {
            email: props.user.email,
            tenant: businessWithRole.business,
          }),
          root.$t('userManagement.userEditModal.actions.removeFromOrganization'),
          {
            showClose: false,
            confirmButtonText: root.$t('userManagement.userEditModal.removeUser'),
            confirmButtonClass: 'el-button--danger',
            cancelButtonText: root.$t('commons.cancel'),
            cancelButtonClass: 'el-button--secondary',
          }
        )
        .then(() => deleteMembership({ id: businessWithRole.membershipId }))
        .then(
          () =>
            (userMemberships.value = userMemberships.value.filter(
              (membership) => membership.membershipId !== businessWithRole.membershipId
            ))
        )
        .catch(() => {});
    };

    return {
      ACTIONS,
      fullName,
      formModel,
      tenantsToAdd,
      activeActions,
      userMemberships,
      createMembership,
      deleteMembership,
      isOtherEmailSelected,
      addMembershipModalOpen,
      createMembershipLoading,
      deleteMembershipLoading,
      handleAction,
      addMembership,
      deleteUserMembership,
      actionsVisibleChange,
      handleSaveButtonClick,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.actions-btn {
  &.active {
    visibility: visible;
  }
  &:hover {
    background: $secondary;
  }
}

li {
  &:hover {
    background: $light-gray;
  }
  .actions-btn {
    visibility: hidden;
  }
  &:hover .actions-btn {
    visibility: visible;
  }
}

::v-deep .edit-user-modal {
  .el-dialog__header,
  .el-dialog__body,
  .el-dialog__footer {
    padding: 0;
    color: $typography-primary;
  }
}
</style>
