import { useQuery, useResult } from '@vue/apollo-composable';
import { gql } from '@apollo/client/core';
import { computed } from 'vue';

import { useNotification } from '@/modules/core';

export const USERS_QUERY = gql`
  query users($first: Int, $after: String, $search: String) {
    users(first: $first, after: $after, search: $search) {
      __typename
      nodes {
        id
        email
        given_name
        family_name
        phone_number
        locale
        picture
        status
        memberships {
          id
          userId
          businessId
          role
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const useUsers = (search) => {
  const { error } = useNotification();
  const { result, loading, onError, refetch } = useQuery(
    USERS_QUERY,
    () => ({ first: 25, search: search.value }),
    () => ({ fetchPolicy: 'cache-first', debounce: 700 })
  );
  const usersConnection = useResult(result, {
    __typename: '',
    nodes: [],
    pageInfo: { endCursor: null, hasNextPage: false },
  });

  const users = computed(() => usersConnection.value.nodes);
  const pageInfo = computed(() => usersConnection.value.pageInfo);

  onError((err) => {
    console.error('auth - useUsers', err);
    error();
  });

  return {
    __typename: usersConnection.value.__typename,
    users,
    pageInfo,
    loading,
    refetch,
  };
};
