import { useQuery } from '@vue/apollo-composable';
import { gql } from '@apollo/client/core';
import { computed } from 'vue';

import { useNotification } from '@/modules/core';

export const BUSINESS_RELATIONS_QUERY = gql`
  query businessesClarityWorkWith($receiverBusinessId: String) {
    businessToBusinessRelationsGet(receiverBusinessId: $receiverBusinessId) {
      nodes {
        providerBusinessId
      }
    }
  }
`;

const CLARITY_BUSINESS_ID = '5ee07c285f7ed4b0d4ac5236';

export const useBusinessesClarityWorkWith = () => {
  const { error } = useNotification();

  const { result, loading, onError, refetch } = useQuery(
    BUSINESS_RELATIONS_QUERY,
    () => ({ receiverBusinessId: CLARITY_BUSINESS_ID }),
    () => ({ fetchPolicy: 'cache-first' })
  );

  const businessesClarityWorksWith = computed(() => [
    ...result.value?.businessToBusinessRelationsGet?.nodes?.map(({ providerBusinessId }) => providerBusinessId),
    CLARITY_BUSINESS_ID,
  ]);
  const totalCount = computed(() => result.value?.businessToBusinessRelationsGet?.totalCount);

  onError((err) => {
    console.error('useBusinessesClarityWorkWith', err);
    error();
  });

  return {
    businessesClarityWorksWith,
    loading,
    totalCount,
    refetch,
  };
};
