import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';

export function useMembershipDelete() {
  const { error } = useNotification();
  const { mutate: deleteMembership, loading, onDone, onError } = useMutation(MEMBERSHIP_DELETE);

  onError((err) => {
    console.error('useMembershipDelete', err);
    error();
  });

  return { deleteMembership, loading, onDone, onError };
}

const MEMBERSHIP_DELETE = gql`
  mutation deleteMembership($id: ID!) {
    membershipDelete(id: $id)
  }
`;
