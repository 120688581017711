import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';

export const useUserEnroll = () => {
  const { mutate, onError, onDone } = useMutation(ENROLL_USER_MUTATION);

  onError((err) => {
    console.error('users - useUserEnroll', err);
  });

  return {
    enrollUser: mutate,
    onError,
    onDone,
  };
};

export const ENROLL_USER_MUTATION = gql`
  mutation userEnroll($userId: String!) {
    userEnroll(userId: $userId)
  }
`;
