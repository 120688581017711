import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';

export function useMembershipCreate() {
  const { error } = useNotification();

  const { mutate: createMembership, loading, onDone, onError } = useMutation(MEMBERSHIP_CREATE_MUTATION);

  onError((param) => {
    console.error('users - useMembershipCreate', param);
    error();
  });

  return {
    createMembership,
    loading,
    onDone,
    onError,
  };
}

const MEMBERSHIP_CREATE_MUTATION = gql`
  mutation createMembership($createParams: MembershipCreateInput!) {
    membershipCreate(createParams: $createParams) {
      id
      userId
      businessId
      role
    }
  }
`;
