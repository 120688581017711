<template>
  <el-dialog
    visible
    width="52rem"
    :show-close="false"
    append-to-body
    custom-class="rounded enrollment-modal"
    @close="$emit('close')"
  >
    <template #title>
      <div class="d-flex justify-content-between">
        <h2>{{ $t('userManagement.enrollmentModal.title') }}</h2>
        <Button type="icon" class="p-0" @click="$emit('close')">
          <CloseIcon />
        </Button>
      </div>
    </template>
    <p class="fw-bold mb-4">
      {{ $t('userManagement.enrollmentModal.subtitle') }}
    </p>
    <div class="user-enrollment-list">
      <div v-for="(enrollment, index) in enrollments" :key="index" class="d-flex align-items-center gap-2 mb-4">
        <Button v-if="enrollments.length > 1" type="text" class="p-0 remove-item-btn" @click="removeEnrollment(index)">
          <CloseIcon width="16px" height="16px" />
        </Button>
        <el-input
          v-model="enrollment.firstName"
          class="property-input"
          :placeholder="$t('userManagement.enrollmentModal.placeholders.firstName')"
        />
        <el-input
          v-model="enrollment.lastName"
          class="property-input"
          :placeholder="$t('userManagement.enrollmentModal.placeholders.lastName')"
        />
        <el-input
          v-model="enrollment.email"
          class="property-input"
          :placeholder="$t('userManagement.enrollmentModal.placeholders.email')"
        />
        <el-select
          v-model="enrollment.businessId"
          clearable
          filterable
          :placeholder="$t('userManagement.enrollmentModal.placeholders.business')"
          class="business-input"
          @visible-change="handleSupplierVisibleChange(index, $event)"
        >
          <el-option
            v-for="tenant in getTenantsOptions(index)"
            :key="tenant.id"
            :label="tenant.name"
            :value="tenant.id"
          />
        </el-select>
        <div class="role-input">
          <MembershipRoleDropdown
            :value="enrollment.role"
            :allowed-roles="allowedRoles(index)"
            :business-id-to-invite-to="enrollment.businessId"
            :business-type="businessType(index)"
            @on-role-change="(newValue) => (enrollment.role = newValue)"
          />
        </div>
      </div>
    </div>
    <!-- Disabled for the moment 6/3/22 -->
    <Button v-if="false" type="text" class="fw-normal" @click="addEnrollment">
      {{ $t('userManagement.enrollmentModal.enrollMore') }}
    </Button>
    <template #footer>
      <Button type="secondary" @click="$emit('close')">{{ $t('commons.cancel') }}</Button>
      <Button type="secondary" :disabled="!isEnrollmentsValid" @click="enroll">{{ $t('commons.send') }}</Button>
    </template>
  </el-dialog>
</template>

<script>
import { computed, ref, getCurrentInstance } from 'vue';
import { CloseIcon } from '@/assets/icons';
import { Button } from '@/modules/core';
import { useUserPermissions } from '@/modules/auth';

import { INVITE_PERMISSIONS } from '@/modules/permissions/constants';
import { MembershipRoleDropdown } from '@/modules/permissions/components';
import { useUserCreate } from '../compositions';
import { BUSINESS_TYPE, ROLE_TYPE } from '../constants';

const getEnrollmentModel = () => ({
  email: null,
  firstName: null,
  lastName: null,
  businessId: null,
  role: null,
});

export default {
  components: {
    Button,
    CloseIcon,
    MembershipRoleDropdown,
  },
  props: {
    tenants: { type: Array, required: true },
  },
  setup(props, { emit }) {
    const root = getCurrentInstance().proxy;
    const enrollments = ref([getEnrollmentModel()]);
    const itemSupplierSelectOpen = ref({});

    const { isUserPermittedForSpecificTenantNonReactive } = useUserPermissions();
    const { createUser, onError, onDone } = useUserCreate();

    const isEnrollmentsValid = computed(() => {
      const model = getEnrollmentModel();

      return enrollments.value.every((enrollment) => Object.keys(model).every((key) => !!enrollment[key]));
    });

    const businessType = (index) => {
      const businessId = enrollments.value[index].businessId;
      const business = props.tenants.find((t) => t.id === businessId);
      return business?.type;
    };

    const allowedRoles = (index) => {
      const businessId = enrollments.value[index].businessId;
      const business = props.tenants.find((t) => t.id === businessId);
      if (business) {
        if (business.type === BUSINESS_TYPE.SERVICE_PROVIDER) return [ROLE_TYPE.ADMIN];
        if (business.type === BUSINESS_TYPE.RESTAURANT)
          return [
            ROLE_TYPE.ORDERER,
            ROLE_TYPE.PURCHASE_MANAGER,
            ROLE_TYPE.MANAGER,
            ROLE_TYPE.PAYMENTS_MANAGER,
            ROLE_TYPE.OWNER,
          ];
      }

      return [];
    };

    const handleSupplierVisibleChange = (index, open) => {
      itemSupplierSelectOpen.value = {
        ...itemSupplierSelectOpen,
        [index]: open,
      };
    };

    const getTenantsOptions = (index) => {
      if (itemSupplierSelectOpen.value[index]) {
        return props.tenants;
      } else if (enrollments.value[index].businessId) {
        return props.tenants.filter((s) => s.id === enrollments.value[index].businessId);
      }
    };

    const removeEnrollment = (index) => {
      enrollments.value.splice(index, 1);
    };

    const addEnrollment = () => {
      enrollments.value.push(getEnrollmentModel());
    };

    const enroll = async () => {
      const loading = root.$loading();
      try {
        const promises = enrollments.value.map(async (userData) => {
          const userInput = {
            email: userData.email,
            given_name: userData.firstName,
            family_name: userData.lastName,
            memberships: [
              {
                businessId: userData.businessId,
                role: userData.role.toUpperCase(),
              },
            ],
          };
          await createUser({ createParams: userInput });
        });
        await Promise.all(promises);
        root.$message.success(root.$t('commons.messages.action.success'));
        emit('close');
      } catch {
        root.$message.error(root.$t('commons.messages.action.error'));
      } finally {
        loading.close();
      }
    };

    const hasPermissionToInviteRole = (index, role) => {
      const relevantBusinessId = enrollments.value[index].businessId;
      switch (role.toUpperCase()) {
        case ROLE_TYPE.ORDERER:
        case ROLE_TYPE.PURCHASE_MANAGER:
          return isUserPermittedForSpecificTenantNonReactive(relevantBusinessId, INVITE_PERMISSIONS.pm_orderer);
        case ROLE_TYPE.MANAGER:
          return isUserPermittedForSpecificTenantNonReactive(relevantBusinessId, INVITE_PERMISSIONS.manager);
        case ROLE_TYPE.PAYMENTS_MANAGER:
          return isUserPermittedForSpecificTenantNonReactive(relevantBusinessId, INVITE_PERMISSIONS.payments_manager);
        case ROLE_TYPE.OWNER:
          return isUserPermittedForSpecificTenantNonReactive(relevantBusinessId, INVITE_PERMISSIONS.owner);
        default:
          return false;
      }
    };

    const handleItemMouseEnter = (event) => {
      event.currentTarget.classList.add('hover');
    };

    const handleItemMouseLeave = (event) => {
      event.currentTarget.classList.remove('hover');
    };

    return {
      enrollments,
      isEnrollmentsValid,
      enroll,
      onDone,
      onError,
      createUser,
      allowedRoles,
      businessType,
      addEnrollment,
      removeEnrollment,
      getTenantsOptions,
      handleItemMouseEnter,
      handleItemMouseLeave,
      hasPermissionToInviteRole,
      handleSupplierVisibleChange,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

::v-deep .enrollment-modal {
  margin-top: 22vh !important;
  $modal-max-height: 60vh;
  $header-height: 3.875rem;
  $footer-height: 3.875rem;
  $max-body-heigh: calc($modal-max-height - $header-height - $footer-height - 1rem);
  max-height: $modal-max-height;
  overflow: hidden;

  .el-dialog__header {
    height: $header-height;
  }
  .el-dialog__header,
  .el-dialog__body,
  .el-dialog__footer {
    padding: 1rem;
    color: $typography-primary;
  }

  .el-dialog__body {
    max-height: $max-body-heigh;
  }

  .user-enrollment-list {
    $title-height: 2.375rem;
    $add-btn-height: 3rem;
    max-height: calc($max-body-heigh - $title-height - $add-btn-height);
    overflow: auto;
  }

  .el-dialog__footer {
    height: $footer-height;
  }

  .el-select-dropdown__item {
    &.hover {
      background-color: #f5f7fa;
    }
    &:hover {
      background-color: transparent !important;
    }
  }
}

.property-input {
  width: 18%;
}
.business-input {
  width: 21%;
}
.role-input {
  width: 19%;
}
.remove-item-btn {
  color: $typography-secondary;
  &:hover {
    color: $typography-primary;
  }
}
</style>
